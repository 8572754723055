import React from 'react';
import {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const headerDetailsSection = {
    display: "flex",
    justifyContent: "space-between",
    gap: "4%",
    marginTop: "20px",
};
const subSections = {
    display: "flex",
    alignItems: "center",
    gap: "15px"
}
const headerIcon = {
    height: "15px",
    minWidth: "15px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer"
};
const facebookLogo = {
    display: "flex",
    alignItems: "center",
    backgroundImage: 'url("/Symbols/facebook.png")', 
    marginRight: "20px"
};
const facebookLogoContainer = {
    height: "auto",
    display: "flex",
    marginLeft: "auto", 
    alignItems: "center",
};
const labelStyle = {
    color: "#7f1518",
    cursor: "pointer",
    minWidth: "100px"
};
const headerLine = {
    height: "1.2px",
    width: "auto",
    backgroundColor: "#7f1518",
    marginTop: "20px"
};
const navigationSection = {
    display: "flex",
    alignItems: "center",
    gap: "10%",
    marginTop: "10px"
}
const logoStyle = {
    minWidth: "140px",
    height: "80px",
    backgroundImage: 'url("/Symbols/logo.png")',
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: "auto",
    cursor: "pointer"
}
const buttonStyle = {
    marginLeft: "auto",
};
const menuBurgerContainer = {
    width: "20px",
    minHeight: "18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
};
const burgerLayer = {
    width: "100%",
    height: "4px",
    backgroundColor: "#7f1518"
};
const hiddenNavigation = {
    position: "fixed",
    right: 0,
    top: 0,
    height: "100vh",
    width: "65vw",
    backgroundColor: "white",
    zIndex: 100,
    padding: "20px",
};
const hiddenNavButtonStyle = {
    minWidth: "70px",
    cursor: "pointer",
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    border: "1px solid #7f1518",
}
const navButtonStyle = {
    minWidth: "70px",
    cursor: "pointer"
}
const headTitleAndImage = {
    display: "flex",
    width: "auto",
    height: "auto",
    margin: "auto auto",
    marginTop: "50px",
    justifyContent: "center",
    alignItems: "center",
};
const columnContainers = {
    marginTop: "40px",
    display: "flex",
    gap: "20px",
    width: "100%",
    justifyContent: "center",
};
const detailsContainer = {
    width: "auto",
};
const imageColumnContainers = {
    minWidth: "220px",
    height: "relative",
};;
const carouselContainer = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    maxWidth: "95%",
    height: "70vh",
    borderRadius: "20px",
    backgroundColor: "white",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
};
const exitButtonStyle = {
    position: "absolute",
    right: 25,
    top: 25,
    cursor: "pointer",
    backgroundImage: "url('/Images/Gallery/exitButton.png')",
    backgroundSize: "contain",
    backgroundPosition: "center",
    height: "20px",
    width: "20px",
};
const navExitButtonStyle = {
    position: "absolute",
    right: 0,
    cursor: "pointer",
    backgroundImage: "url('/Images/Gallery/exitButton.png')",
    backgroundSize: "contain",
    backgroundPosition: "center",
    minHeight: "20px",
    minWidth: "20px",
};
const arrowLeft = {
    position: "absolute",
    top: "50%",
    left: 25,
    height: "15px",
    width: "15px",
    backgroundImage: "url('/Images/Gallery/browsingArrow.png')",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    transform: "rotate(180deg)",
    cursor: "pointer",
    display: "none"
};
const arrowRight = {
    position: "absolute",
    top: "50%",
    right: 25,
    height: "15px",
    width: "15px",
    backgroundImage: "url('/Images/Gallery/browsingArrow.png')",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    display: "none"
};
const imageContainerStyle = {
    width: "100%",
    height: "270px",
    borderRadius: "25px",
    marginBottom: "12px",
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
}
const footerSection = {
    width: "100%",
};

const column1 = [
    "/Images/Gallery/image1.jpeg",
    "/Images/Gallery/image2.jpeg",
    "/Images/Gallery/image3.jpeg",
    "/Images/Gallery/image4.jpeg",
    "/Images/Gallery/image5.jpeg",
    "/Images/Gallery/image6.jpeg",
    "/Images/Gallery/image7.jpeg",
    "/Images/Gallery/image8.jpeg"
];
const column2 = [
    "/Images/Gallery/image9.jpeg",
    "/Images/Gallery/image10.jpeg",
    "/Images/Gallery/image11.jpeg",
    "/Images/Gallery/image12.jpeg",
    "/Images/Gallery/image13.jpeg",
    "/Images/Gallery/image14.jpeg",
    "/Images/Gallery/image15.jpeg",
    "/Images/Gallery/image16.jpeg"
];
const column3 = [
    "/Images/Gallery/image17.jpeg",
    "/Images/Gallery/image18.jpeg",
    "/Images/Gallery/image19.jpeg",
    "/Images/Gallery/image20.jpeg",
    "/Images/Gallery/image21.jpeg",
    "/Images/Gallery/image22.jpeg",
    "/Images/Gallery/image23.jpeg",
    "/Images/Gallery/image24.jpeg"
];
const column4 = [
    "/Images/Gallery/image25.jpeg",
    "/Images/Gallery/image26.jpeg",
    "/Images/Gallery/image27.jpeg",
    "/Images/Gallery/image28.jpeg",
    "/Images/Gallery/image29.jpeg",
    "/Images/Gallery/image30.jpeg",
    "/Images/Gallery/image31.jpeg",
    "/Images/Gallery/image32.jpeg"
];
const column5 = [
    "/Images/Gallery/image33.jpeg",
    "/Images/Gallery/image34.jpeg",
    "/Images/Gallery/image35.jpeg",
    "/Images/Gallery/image36.jpeg",
    "/Images/Gallery/image37.jpeg",
    "/Images/Gallery/image38.jpeg",
    "/Images/Gallery/image39.jpeg",
    "/Images/Gallery/image40.jpeg"
];

export default function GalleryExport() {
    
    let navigate = useNavigate();

    const [carouselOn, setCarouselOn] = useState(false);
    const [hiddenNavOn, setHiddenNavOn] = useState(false);
    const [focusImage, setFocusImage] = useState(["", ""]);
    const [mainElementsStyle, setMainElementsStyle] = useState({});
    const [screenWidthState, setScreenWidthState] = useState(null);

    const xCloseButtonRef = useRef(null);
    const xNavCloseButtonRef = useRef(null);
    const arrowLeftRef = useRef(null);
    const arrowRightRef = useRef(null);
    const mainElementsRef = useRef(null);
    const hiddenNavigationRef = useRef(null);
    const burgerButtonRef = useRef(null);



    useEffect(() => {
        const handleWidthChange = () => {
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            setScreenWidthState(windowWidth)
        };
        setScreenWidthState(window.innerWidth)
        window.addEventListener("resize", handleWidthChange);
        xCloseButtonRef.current.addEventListener("mouseenter", () => {
            xCloseButtonRef.current.style.animation = "rotateForwards 0.2s ease-in forwards";
            xNavCloseButtonRef.current.style.animation = "rotateForwards 0.2s ease-in forwards"
        });
        xCloseButtonRef.current.addEventListener("mouseleave", () => {
            xCloseButtonRef.current.style.animation = "rotateBackwards 0.2s ease-in forwards";
        });
        xNavCloseButtonRef.current.addEventListener("mouseenter", () => {
            xNavCloseButtonRef.current.style.animation = "rotateForwards 0.2s ease-in forwards"
        });
        xNavCloseButtonRef.current.addEventListener("mouseleave", () => {
            xNavCloseButtonRef.current.style.animation = "rotateBackwards 0.2s ease-in forwards";
        });
        document.addEventListener("click", (event) => {
            const triggeredElement = event.target.getAttribute("id");
            if(triggeredElement !== "carouselElement"){
                setCarouselOn(false)
            }
        })
        return () => {
            window.removeEventListener("resize", handleWidthChange);
        }
    }, []);


    useEffect(() => {
        if(hiddenNavOn === true){
            hiddenNavigationRef.current.style.display = "initial";
            hiddenNavigationRef.current.style.animation = "rightIn 0.2s ease-out forwards";
            burgerButtonRef.current.style.animation = "";
            document.body.style.overflow = "hidden";
            const overlayStyle = {
                position: "fixed",
                height: "100vh",
                width: "100vw",
                top: 0,
                left: 0,
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                pointerEvents: "none",
            }
            setMainElementsStyle(overlayStyle);
        };
        if(hiddenNavOn === false){
            hiddenNavigationRef.current.style.animation = "rightOut 0.2s ease-in";
            burgerButtonRef.current.style.animation = "burgerLayerOut 0.3s ease-in forwards";
            document.body.style.overflow = "auto";
            hiddenNavigationRef.current.style.display = "none"
            setMainElementsStyle({});
        };
    }, [hiddenNavOn])


    const iconClean = (icon) => {
        switch(true){
            case icon === "mail":
                const subject = "Thabo was here";
                const body = "I want to ask you a question";
                const recipient = "mjpressurecleaning@gmail.com"
                const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                window.location.href = mailtoLink;
                break;
            case icon === "phone":
                window.location.href = "tel:0492964320"
                break;
            case icon === "location":
                window.location.href = "https://www.google.com/maps?q=34-36+Clithero+Avenue+Buderim"
                break;
            case icon === "facebook":
                window.location.href = "https://www.facebook.com"
                break;
            case icon === "refresh":
                navigate("/")
                break;
            case icon === "getQuote":
                navigate("/getQuote")
                break;
            case icon === "gallery":
                navigate("/gallery")
                break;
            default:
                console.log("done")
        }
    };

    const handleFocusChange = (image, index) => {
        setCarouselOn(true);
        setFocusImage([image, index]);
        console.log("Original: ", [image, index])
    };
    const handleBrowseLeft = () => {
        setFocusImage(previous => {
            const newArray = [`/images/Gallery/image${previous[1] - 1 > 1?previous[1] - 1:1}.jpeg`, previous[1] - 1 > 1?previous[1] - 1: 1];
            console.log("New picture: ", newArray)
            return newArray;
        });
    };
    const handleBrowseRight = () => {
        setFocusImage(previous => {
            const newArray = [`/images/Gallery/image${previous[1] + 1 <= 40?previous[1] + 1:40}.jpeg`, previous[1] + 1 <= 40?previous[1] + 1: 40];
            console.log("New picture: ", newArray)
            return newArray;
        });
    };

    useEffect(() => {
        const arrowDownEvent = (event) => {
            const keyDown = event.key;
            if(keyDown === "ArrowLeft" && carouselOn === true){
                handleBrowseLeft();
            };
            if(keyDown === "ArrowRight" && carouselOn === true){
                handleBrowseRight();
            }
        };
        const handleMouseOverArrowLeft = () => {
            arrowLeftRef.current.style.opacity = "0.7";
        };
        const handleMouseOverArrowRight = () => {
            arrowRightRef.current.style.opacity = "0.7";
        };
        const arrowNormalLeft = () => {
            arrowLeftRef.current.style.opacity = "1";
        };
        const arrowNormalRight = () => {
            arrowRightRef.current.style.opacity = "1";
        };
        document.addEventListener("keydown", arrowDownEvent);
        arrowLeftRef.current.addEventListener("mouseenter", handleMouseOverArrowLeft);
        arrowRightRef.current.addEventListener("mouseenter", handleMouseOverArrowRight);
        arrowLeftRef.current.addEventListener("mouseleave", arrowNormalLeft);
        arrowRightRef.current.addEventListener("mouseleave", arrowNormalRight);

        if(carouselOn === true){
            document.body.style.overflow = "hidden"
            const overlayStyle = {
                position: "absolute",
                height: "100%",
                width: "100vw",
                top: 0,
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                pointerEvents: "none"
            }
            setMainElementsStyle(overlayStyle);
        };
        if(carouselOn === false){
            document.body.style.overflow = "auto";
            setMainElementsStyle({});
        }

        return () => {
            document.removeEventListener("keydown", arrowDownEvent);
        };
    }, [carouselOn])
    
  return (
    <>
        <style>
            {
                `
                @keyframes rotateForwards{
                    0%{
                        transform: rotate(0deg)
                    }
                    100%{
                        transform: rotate(180deg)
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rotateBackwards{
                    0%{
                        transform: rotate(180deg)
                    }
                    100%{
                        transform: rotate(0deg)
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rightIn{
                    0%{
                        margin-right: -200px
                    }
                    100%{
                        margin-right: 0px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rightOut{
                    0%{
                        margin-right: 0px
                    }
                    100%{
                        margin-right: -200px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes burgerLayerOut{
                    0%{
                        width: 0px
                    }
                    100%{
                        width: 20px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes navPanelsSlideIn{
                    0%{
                        margin-top: 30px
                    }
                    100%{
                        margin-top: 0px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes fadeIn{
                    0%{
                        opacity: 0.3
                    }
                    100%{
                        opacity: 1
                    }
                }
                `
            }
        </style>
        <head>
            <title>Gallery</title>
        </head>
        <div style={{...headerDetailsSection, gap: screenWidthState < 500?"30px":"4%"}}>
            <div style={subSections}>
                <div onClick={() => iconClean("mail")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/mail.png")'}}>

                </div>
                <p onClick={() => iconClean("mail")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"60px":"100px"}}>{screenWidthState < 500?"mjpres...":screenWidthState < 900?"mjpressure...":"mjpressurecleaning@gmail.com"}</p>
            </div>
            <div style={subSections}>
                <div onClick={() => iconClean("phone")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/phone.png")'}}>

                </div>
                <p onClick={() => iconClean("phone")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"60px":"100px"}}>{screenWidthState < 500?"0492...":screenWidthState < 900?"0492 96...":"0492 964 320"}</p>
            </div>
            <div style={subSections}>
                <div onClick={() => iconClean("location")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/location.png")'}}>

                </div>
                <p onClick={() => iconClean("location")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"80px":"200px"}}>{screenWidthState < 500?"34...":screenWidthState < 900?"34 Clithero...":"34-36 Clithero Avenue Buderim"}</p>
            </div>
            <div style={facebookLogoContainer}>
                <div onClick={() => iconClean("facebook")} className={"buttonHover"} style={{...headerIcon, ...facebookLogo, marginRight: screenWidthState < 900?"":"20px"}}>

                </div>
            </div>
        </div>
        <div style={{...headerLine, marginTop: screenWidthState < 500?"15px":"20px"}}>

        </div>
        <div style={navigationSection}>
            <div onClick={() => iconClean("refresh")} style={{...logoStyle, animation: "fadeIn 0.4s ease-in forwards"}}>

            </div>
            <p onClick={() => iconClean("refresh")} className={"buttonHover"} style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Home</p>
            <p onClick={() => iconClean("gallery")} className={"buttonHover"}  style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Gallery</p>
            <p onClick={() => iconClean("getQuote")} className={"buttonHover"}  style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Get Quote</p>
            <Link to="/contact" style={{...buttonStyle, display: screenWidthState < 900?"none":"initial"}}><button>Contact</button></Link>
            <div ref={burgerButtonRef} onClick={() => setHiddenNavOn(true)} style={{...menuBurgerContainer, display: screenWidthState < 900?"flex":"none"}}>
                <div style={{...burgerLayer, position: "absolute", top: 0}}></div>
                <div style={burgerLayer}></div>
                <div style={{...burgerLayer, position: "absolute", bottom: 0}}></div>
            </div>
            <div ref={hiddenNavigationRef} style={hiddenNavigation}>
                <div style={{display: "flex", alignItems: "center", position: "relative", height: "30px"}}>
                    <h3>
                        Menu
                    </h3>
                    <div id={"carouselElement"} ref={xNavCloseButtonRef} onClick={() => setHiddenNavOn(prev => !prev)} style={navExitButtonStyle}>

                    </div>
                </div>
                <div style={headerLine}>

                </div>
                <div style={{width: "100%", height: "10px"}}>

                </div>
                <p onClick={() => iconClean("refresh")} className={"buttonHover"} style={{...hiddenNavButtonStyle}}>Home</p>
                <p onClick={() => iconClean("gallery")} className={"buttonHover"}  style={{...hiddenNavButtonStyle, animation: "navPanelsSlideIn 0.4s ease-in forwards"}}>Gallery</p>
                <p onClick={() => iconClean("getQuote")} className={"buttonHover"}  style={{...hiddenNavButtonStyle, animation: "navPanelsSlideIn 0.4s ease-in forwards"}}>Get Quote</p>
            </div>
        </div>
        <div style={{...headTitleAndImage, marginTop: screenWidthState < 900?"20px":"50px", animation: "fadeIn 0.5s ease-in forwards"}}>
            <div style={{...detailsContainer, width: screenWidthState < 775?"100%":""}}>
                <h1 style={{fontSize: screenWidthState < 500?"22px":screenWidthState < 900?"30px":""}}>Previous Jobs</h1>
                <h2 style={{fontSize: screenWidthState < 500?"15px":screenWidthState < 900?"16px":""}}>Browse our gallery of previous works below.</h2>
                <div style={{...columnContainers, width: screenWidthState < 500?"100%":"relative", marginTop: screenWidthState < 775?"25px":"40px"}}>
                    <div style={{...imageColumnContainers, minWidth: screenWidthState < 755?"":"225px",width: screenWidthState < 775?"50%":""}}>
                        {column1.map((image, index) => {
                            return <div id={"carouselElement"} onClick={() => handleFocusChange(image, index + 1)} style={{...imageContainerStyle, height: screenWidthState < 500?"200px":"270px",backgroundImage: `url('${image}')`}}>
                            
                            </div>
                        })}
                    </div>
                    <div style={{...imageColumnContainers, minWidth: screenWidthState < 755?"":"225px", width: screenWidthState < 775?"50%":""}}>
                        {column2.map((image, index) => {
                            return <div  id={"carouselElement"} onClick={() => handleFocusChange(image, index + 9)} style={{...imageContainerStyle, height: screenWidthState < 500?"200px":"270px", backgroundImage: `url('${image}')`}}>
                            
                            </div>
                        })}
                    </div>
                    <div style={{...imageColumnContainers, display: screenWidthState < 775?"none":""}}>
                        {column3.map((image, index) => {
                            return <div id={"carouselElement"} onClick={() => handleFocusChange(image, index + (8 * 2) + 1)} style={{...imageContainerStyle, backgroundImage: `url('${image}')`}}>
                            
                            </div>
                        })}
                    </div>
                    <div style={{...imageColumnContainers, display: screenWidthState < 980?"none":""}}>
                        {column4.map((image, index) => {
                            return <div id={"carouselElement"} onClick={() => handleFocusChange(image, index + (8 * 3) + 1)} style={{...imageContainerStyle, backgroundImage: `url('${image}')`}}>
                            
                            </div>
                        })}
                    </div>
                    <div style={{...imageColumnContainers, display: screenWidthState < 1280?"none":""}}>
                        {column5.map((image, index) => {
                            return <div id={"carouselElement"} onClick={() => handleFocusChange(image, index + (8 * 4) + 1)} style={{...imageContainerStyle, backgroundImage: `url('${image}')`}}>
                            
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div ref={mainElementsRef} style={mainElementsStyle}>

            </div>
        </div>
        <div id={"carouselElement"} style={{...carouselContainer, display: `${carouselOn?"inherit":"none"}`, backgroundImage: `url('${focusImage[0]}')`}}>
                    <div id={"carouselElement"} ref={xCloseButtonRef} onClick={() => setCarouselOn(false)} style={exitButtonStyle}>

                    </div>
                    <div ref={arrowLeftRef} id={"carouselElement"} onClick={() => handleBrowseLeft()} style={arrowLeft}>

                    </div>
                    <div ref={arrowRightRef} id={"carouselElement"} onClick={() => handleBrowseRight()} style={arrowRight}>

                    </div>
        </div>
        <div style={footerSection}>
            <div style={{...headerLine, height: "0.8px", marginTop: screenWidthState < 500?"50px":"100px"}}>

            </div>
            <div style={navigationSection}>
                <div onClick={() => iconClean("refresh")} style={{...logoStyle, minWidth: screenWidthState < 500?"80px":screenWidthState < 580?"120px":"140px", height: screenWidthState < 500?"80px":screenWidthState < 580?"90px":"80px"}}>

                </div>
                <p style={{...navButtonStyle, curosr: "initial"}}>by Montarch Web Design</p>
                <div style={facebookLogoContainer}>
                    <div onClick={() => iconClean("facebook")} class={"buttonHover"} style={{...headerIcon, ...facebookLogo}}>

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
