import React from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {useState, useEffect, useRef} from "react";
import "./home.css"


const headerDetailsSection = {
    display: "flex",
    justifyContent: "space-between",
    gap: "4%",
    marginTop: "20px"
};
const subSections = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
}
const headerIcon = {
    height: "15px",
    minWidth: "15px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
};
const facebookLogo = {
    display: "flex",
    alignItems: "center",
    backgroundImage: 'url("/Symbols/facebook.png")', 
};
const facebookLogoContainer = {
    height: "auto",
    display: "flex",
    marginLeft: "auto", 
    alignItems: "center",
};
const labelStyle = {
    color: "#7f1518",
    cursor: "pointer",
};
const headerLine = {
    height: "1.2px",
    width: "100%",
    backgroundColor: "#7f1518",
    marginTop: "20px"
};
const navigationSection = {
    display: "flex",
    alignItems: "center",
    gap: "10%",
    marginTop: "10px",
}
const logoStyle = {
    minWidth: "140px",
    height: "80px",
    backgroundImage: 'url("/Symbols/logo.png")',
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: "auto",
    cursor: "pointer"
}
const buttonStyle = {
    marginLeft: "auto",
}
const navButtonStyle = {
    minWidth: "70px",
    cursor: "pointer",
};
const hiddenNavButtonStyle = {
    minWidth: "70px",
    cursor: "pointer",
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    border: "1px solid #7f1518",
}
const menuBurgerContainer = {
    width: "20px",
    minHeight: "18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
};
const burgerLayer = {
    width: "100%",
    height: "4px",
    backgroundColor: "#7f1518"
};
const hiddenNavigation = {
    position: "fixed",
    right: 0,
    top: 0,
    height: "100vh",
    width: "65vw",
    backgroundColor: "white",
    zIndex: 100,
    padding: "20px",
};
const exitButtonStyle = {
    position: "absolute",
    right: 0,
    cursor: "pointer",
    backgroundImage: "url('/Images/Gallery/exitButton.png')",
    backgroundSize: "contain",
    backgroundPosition: "center",
    minHeight: "20px",
    minWidth: "20px",
};
const headTitleAndImage = {
    display: "flex",
    marginTop: "40px",
    columnGap: "20px",
}
const headImage = {
    height: "320px",
    minWidth: "480px",
    backgroundImage: 'url("/Images/sunshineCoast.jpeg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "25px"
};
const imageBackDrop = {
    backgroundImage: 'url("/Symbols/secondPressureGuns.png")',
    backgroundSize: "contain",
    height: "800px", 
    width: "100%",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    zIndex: -200,
    top: 280,
    left: 0,
    filter: "opacity(0.1)"
}
const formSection = {
    gap: "40px",
    height: "auto",
}
const formSubsection = {
    height: "auto",
    padding: "50px 40px 35px 40px",
    textAlign: "center",
    backgroundColor: "white",
    boxSizing: "border-box"
};
const formHeading = {
    marginBottom: "12px",
    filter: 'opacity(0.9)'
}
const formSubheading = {
    marginBottom: "35px",
    filter: 'opacity(0.8)'
}
const formInputStyle = {
    width: "100%",
    marginBottom: "15px"
};
const formTextAreaStyle = {
    width: "100%",
    marginBottom: "25px"
}
const galleryContactMeSection = {
    display: "flex",
    gap: "18px",
};
const gcSubsection = {
    width: "50%",
    height: "auto",
}
const galleryImage = {
    width: "100%",
    borderRadius: "20px",
    backgroundImage: 'url("/Images/roofClean.jpeg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    marginBottom: "20px"
}
const bottomButton = {
    position: "absolute",
    bottom: 20,
    right: 20
}
const slideShowContainer = {
    width: "100%",
    borderRadius: "20px",
    backgroundImage: 'url("/Images/flatWash.jpeg")'
}
const reviewBox = {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    padding: "20px 30px 20px 30px",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
    marginBottom: "20px",
    backgroundColor: "white",
    zIndex: -300,
};
const contactImage = {
    width: "100%",
    borderRadius: "20px",
    backgroundImage: 'url("/Images/outings.png")',
    position: "relative",
};


const retrievedReviews = [
    {
        name: "Katherine Boyle",
        review: "I absolutely loved the job...",
        date: "11 03 24"
    },
    {
        name: "Sarah Wakefield",
        review: "I absolutely loved the job...",
        date: "11 03 24"
    },
    {
        name: "Matt Samuels",
        review: "I absolutely loved the job...",
        date: "11 03 24"
    },
    {
        name: "Ella Miller",
        review: "I absolutely loved the job...",
        date: "11 03 24"
    },
    {
        name: "Bill Nye",
        review: "I absolutely loved the job...",
        date: "11 03 24"
    }
]



export default function HomeExport() {

    let navigate = useNavigate();

    const iconClean = (icon) => {
        switch(true){
            case icon === "mail":
                const subject = "Thabo was here";
                const body = "I want to ask you a question";
                const recipient = "mjpressurecleaning@gmail.com"
                const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                window.location.href = mailtoLink;
                break;
            case icon === "phone":
                window.location.href = "tel:0492964320"
                break;
            case icon === "location":
                window.location.href = "https://www.google.com/maps?q=34-36+Clithero+Avenue+Buderim"
                break;
            case icon === "facebook":
                window.location.href = "https://www.facebook.com"
                break;
            case icon === "refresh":
                navigate("/")
                break;
            case icon === "getQuote":
                navigate("/getQuote")
                break;
            case icon === "gallery":
                navigate("/gallery")
                break;
            default:
                console.log("done")
        };
    };

    const [screenWidthState, setScreenWidthState] = useState(null);
    const [mainElementsStyle, setMainElementsStyle] = useState({});
    const [carouselOn, setCarouselOn] = useState(false);
    const [currentReview, setCurrentReview] = useState(0);


    const xCloseButtonRef = useRef(null);
    const mainElementsRef = useRef(null);
    const hiddenNavigationRef = useRef(null);
    const burgerButtonRef = useRef(null);

    useEffect(() => {
        setInterval(() => {
            setCurrentReview(prev => {
                if(prev + 1 < retrievedReviews.length){
                    return prev + 1;
                };
                return 0;
            })
        }, 5000);
        const handleWidthChange = () => {
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            setScreenWidthState(windowWidth)
        };
        setScreenWidthState(window.innerWidth)
        window.addEventListener("resize", handleWidthChange);

        xCloseButtonRef.current.addEventListener("mouseenter", () => {
            xCloseButtonRef.current.style.animation = "rotateForwards 0.2s ease-in forwards"
        });
        xCloseButtonRef.current.addEventListener("mouseleave", () => {
            xCloseButtonRef.current.style.animation = "rotateBackwards 0.2s ease-in forwards"
        });

        return () => {
            window.removeEventListener("resize", handleWidthChange);
        }
    }, []);

    useEffect(() => {
        if(carouselOn === true){
            hiddenNavigationRef.current.style.display = "initial";
            hiddenNavigationRef.current.style.animation = "rightIn 0.2s ease-out forwards";
            burgerButtonRef.current.style.animation = "";
            document.body.style.overflow = "hidden";
            const overlayStyle = {
                position: "fixed",
                height: "100vh",
                width: "100vw",
                top: 0,
                left: 0,
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                pointerEvents: "none",
            }
            setMainElementsStyle(overlayStyle);
        };
        if(carouselOn === false){
            hiddenNavigationRef.current.style.animation = "rightOut 0.2s ease-in";
            burgerButtonRef.current.style.animation = "burgerLayerOut 0.3s ease-in forwards";
            document.body.style.overflow = "auto";
            hiddenNavigationRef.current.style.display = "none"
            setMainElementsStyle({});
        };
    }, [carouselOn])

    
  return (
    <>
        <style>
            {
                `
                @keyframes rotateForwards{
                    0%{
                        transform: rotate(0deg)
                    }
                    100%{
                        transform: rotate(180deg)
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rotateBackwards{
                    0%{
                        transform: rotate(180deg)
                    }
                    100%{
                        transform: rotate(0deg)
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rightIn{
                    0%{
                        margin-right: -200px
                    }
                    100%{
                        margin-right: 0px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rightOut{
                    0%{
                        margin-right: 0px
                    }
                    100%{
                        margin-right: -200px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes burgerLayerOut{
                    0%{
                        width: 0px
                    }
                    100%{
                        width: 20px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes navPanelsSlideIn{
                    0%{
                        margin-top: 30px
                    }
                    100%{
                        margin-top: 0px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes fadeIn{
                    0%{
                        opacity: 0.3
                    }
                    100%{
                        opacity: 1
                    }
                }
                `
            }
        </style>

        <div style={{...headerDetailsSection, gap: screenWidthState < 500?"30px":"4%"}}>
            <div style={subSections}>
                <div onClick={() => iconClean("mail")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/mail.png")'}}>

                </div>
                <p onClick={() => iconClean("mail")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"60px":"100px"}}>{screenWidthState < 500?"mjpres...":screenWidthState < 900?"mjpressure...":"mjpressurecleaning@gmail.com"}</p>
            </div>
            <div style={subSections}>
                <div onClick={() => iconClean("phone")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/phone.png")'}}>

                </div>
                <p onClick={() => iconClean("phone")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"60px":"100px"}}>{screenWidthState < 500?"0492...":screenWidthState < 900?"0492 96...":"0492 964 320"}</p>
            </div>
            <div style={subSections}>
                <div onClick={() => iconClean("location")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/location.png")'}}>

                </div>
                <p onClick={() => iconClean("location")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"80px":"200px"}}>{screenWidthState < 500?"34...":screenWidthState < 900?"34 Clithero...":"34-36 Clithero Avenue Buderim"}</p>
            </div>
            <div style={facebookLogoContainer}>
                <div onClick={() => iconClean("facebook")} className={"buttonHover"} style={{...headerIcon, ...facebookLogo, marginRight: screenWidthState < 900?"":"20px"}}>

                </div>
            </div>
        </div>
        <div style={{...headerLine, marginTop: screenWidthState < 500?"15px":"20px"}}>

        </div>
        <div style={navigationSection}>
            <div onClick={() => iconClean("refresh")} style={{...logoStyle, animation: "fadeIn 0.4s ease-in forwards"}}>

            </div>
            <p onClick={() => iconClean("refresh")} className={"buttonHover"} style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Home</p>
            <p onClick={() => iconClean("gallery")} className={"buttonHover"}  style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Gallery</p>
            <p onClick={() => iconClean("getQuote")} className={"buttonHover"}  style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Get Quote</p>
            <Link to="/contact" style={{...buttonStyle, display: screenWidthState < 900?"none":"initial"}}><button>Contact</button></Link>
            <div ref={burgerButtonRef} onClick={() => setCarouselOn(true)} style={{...menuBurgerContainer, display: screenWidthState < 900?"flex":"none"}}>
                <div style={{...burgerLayer, position: "absolute", top: 0}}></div>
                <div style={burgerLayer}></div>
                <div style={{...burgerLayer, position: "absolute", bottom: 0}}></div>
            </div>
            <div ref={hiddenNavigationRef} style={{...hiddenNavigation, display: carouselOn === false?"none":""}}>
                <div style={{display: "flex", alignItems: "center", position: "relative", height: "30px"}}>
                    <h3>
                        Menu
                    </h3>
                    <div id={"carouselElement"} ref={xCloseButtonRef} onClick={() => setCarouselOn(prev => !prev)} style={exitButtonStyle}>

                    </div>
                </div>
                <div style={headerLine}>

                </div>
                <div style={{width: "100%", height: "10px"}}>

                </div>
                <p onClick={() => iconClean("refresh")} className={"buttonHover"} style={{...hiddenNavButtonStyle}}>Home</p>
                <p onClick={() => iconClean("gallery")} className={"buttonHover"}  style={{...hiddenNavButtonStyle, animation: "navPanelsSlideIn 0.4s ease-in forwards"}}>Gallery</p>
                <p onClick={() => iconClean("getQuote")} className={"buttonHover"}  style={{...hiddenNavButtonStyle, animation: "navPanelsSlideIn 0.4s ease-in forwards"}}>Get Quote</p>
            </div>
        </div>
        <div style={{display: "flex", width: "100%", animation: "fadeIn 0.5s ease-in forwards"}}>
            <div style={{minWidth: "25px", display: screenWidthState < 900?"none":""}}>

            </div>
            <div style={{width: "100%", position: "relative"}}>
                <div style={{...headTitleAndImage,
                    marginLeft: screenWidthState < 1340?"auto":"initial",
                    marginRight: screenWidthState < 1340?"auto":"initial",
                    marginTop: screenWidthState < 900?"20px":"40px",
                    width: screenWidthState < 900?"100%":screenWidthState < 1340?"750px":"initial",
                }}>
                    <div>
                        <h1 style={{fontSize: screenWidthState < 500?"22px":screenWidthState < 900?"30px":""}}>Local Sunshine Coast based high quality pressure cleaning service</h1>
                        <h2 style={{fontSize: screenWidthState < 500?"15px":screenWidthState < 900?"16px":""}}>Hi, my name is Marcus Jen, the owner of MJ Pressure Cleaning, where your dream shining tiles are just around the corner. Get a quote today!</h2>
                    </div>
                    <div style={{...headImage, display: screenWidthState < 1340?"none":"initial"}}>

                    </div>
                </div>
                <div style={{...imageBackDrop, top: screenWidthState < 580?120:280}}>

                </div>
                <div className={"formAndDetailsContainer"} style={{...formSection,
                    width: screenWidthState < 900?"100%":screenWidthState < 1340?"750px":"initial",
                    marginLeft: screenWidthState < 1340?"auto":"initial",
                    marginRight: screenWidthState < 1340?"auto":"initial",
                    display: screenWidthState < 1340?"flex":"",
                    flexDirection: screenWidthState < 1340?"column-reverse":"initial",
                    marginTop: screenWidthState < 500?"-10px":screenWidthState < 1340?"10px":"100px"
                }}>
                    <form target={"_blank"} action={"https://formspree.io/f/mbjnerek"} method={"POST"} style={{...formSubsection,
                                marginLeft: screenWidthState < 1340?"auto":"initial",
                                marginRight: screenWidthState < 1340?"auto":"initial",
                                width: screenWidthState < 1340?"100%":"500px",
                                minWidth: screenWidthState > 1340?"600px":"0px"
                                }}>
                        <h3 style={{...formHeading, fontSize: screenWidthState < 500?"20px":"25px"}}>{screenWidthState < 500?"Leave us your details.":"Tell Us About Your Project."}</h3>
                        <p style={formSubheading}>{screenWidthState < 500?"Replies will take 24h":"Replies will take 24 hours. So, give us some details about your project and anything that may of interest to this service provision."}</p>
                        <input style={formInputStyle} type="text" name="First Name" placeholder="First Name" required/>
                        <input style={formInputStyle} type="text" name="Last Name" placeholder="Last Name" required/>
                        <input style={{...formInputStyle, display: screenWidthState < 500?"none":"block"}} type="text" name="Email" placeholder="Email"/>
                        <input style={formInputStyle} type="text" name="Phone Number" placeholder="Phone Number" required/>
                        <input style={{...formInputStyle}} type="text" name="Suburb" placeholder="Suburb" required/>
                        <textarea style={{...formTextAreaStyle, display: screenWidthState < 500?"none":"block",height: screenWidthState < 900?"150px":"200px"}} type="text" name="Enquiry" placeholder="Inquiry Details" />
                        <button style={{marginTop: screenWidthState < 500?"10px":""}} type="submit">Submit</button>
                    </form>
                    <div style={{...galleryContactMeSection, width: "100%", marginTop: screenWidthState < 1340?"40px":"0px"}}>
                        <div style={gcSubsection}>
                            <div style={{...galleryImage, height: screenWidthState < 500?"220px":screenWidthState < 580?"300px":"380px"}}>
                                <button onClick={() => iconClean("gallery")} style={bottomButton} className={"whiteBtn"}>Gallery</button>
                            </div>
                            <div className={"backgroundImageSettings"} style={{...slideShowContainer, height: screenWidthState < 500?"240px":screenWidthState < 580?"315px":"405px"}}>

                            </div>
                        </div>
                        <div style={gcSubsection}>
                            <div style={{...reviewBox, height: screenWidthState < 500?"160px":screenWidthState < 580?"200px":"260px"}}>
                                <h5 style={{marginBottom: "15px"}}>{retrievedReviews[currentReview].name}</h5>
                                <p>{retrievedReviews[currentReview].review}</p>
                                <p>{retrievedReviews[currentReview].date}</p>
                            </div>
                            <div className={"backgroundImageSettings"} style={{...contactImage, height: screenWidthState < 500?"260px":screenWidthState < 580?"375px":"475px"}}>
                                <Link to="/contact" style={bottomButton}><button className={"whiteBtn"}>Contact Me</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{minWidth: "25px", display: screenWidthState < 900?"none":""}}>

            </div>
        </div>
        <div style={{...headerLine, height: "0.8px", marginTop: screenWidthState < 500?"50px":"100px"}}>

        </div>
        <div style={{...navigationSection, marginTop: "0px"}}>
            <div onClick={() => iconClean("refresh")} style={{...logoStyle, minWidth: screenWidthState < 500?"80px":screenWidthState < 580?"120px":"140px", height: screenWidthState < 500?"80px":screenWidthState < 580?"90px":"80px"}}>

            </div>
            <p style={{...navButtonStyle, cursor: "initial"}}>by Montarch Web Design</p>
            <div style={facebookLogoContainer}>
                <div onClick={() => iconClean("facebook")} className={"buttonHover"} style={{...headerIcon, ...facebookLogo}}>

                </div>
            </div>
        </div>
        <div ref={mainElementsRef} style={mainElementsStyle}>

        </div>
    </>
  )
}
