import React from 'react';
import { Link } from 'react-router-dom';
import {useState, useEffect, useRef} from "react";
import { useNavigate } from 'react-router';

const headerDetailsSection = {
    display: "flex",
    justifyContent: "space-between",
    gap: "4%",
    marginTop: "20px",
};
const subSections = {
    display: "flex",
    alignItems: "center",
    gap: "15px"
}
const headerIcon = {
    height: "15px",
    minWidth: "15px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer"
};
const facebookLogo = {
    display: "flex",
    alignItems: "center",
    backgroundImage: 'url("/Symbols/facebook.png")', 
    marginRight: "20px"
};
const facebookLogoContainer = {
    height: "auto",
    display: "flex",
    marginLeft: "auto", 
    alignItems: "center",
};
const labelStyle = {
    color: "#7f1518",
    cursor: "pointer",
    minWidth: "100px"
};
const headerLine = {
    height: "1.2px",
    width: "auto",
    backgroundColor: "#7f1518",
    marginTop: "20px"
};
const navigationSection = {
    display: "flex",
    alignItems: "center",
    gap: "10%",
    marginTop: "10px"
}
const logoStyle = {
    minWidth: "140px",
    height: "80px",
    backgroundImage: 'url("/Symbols/logo.png")',
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: "auto",
    cursor: "pointer"
};
const menuBurgerContainer = {
    width: "20px",
    minHeight: "18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
};
const burgerLayer = {
    width: "100%",
    height: "4px",
    backgroundColor: "#7f1518"
};
const hiddenNavigation = {
    position: "fixed",
    right: 0,
    top: 0,
    height: "100vh",
    width: "65vw",
    backgroundColor: "white",
    zIndex: 100,
    padding: "20px",
};
const hiddenNavButtonStyle = {
    minWidth: "70px",
    cursor: "pointer",
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    border: "1px solid #7f1518",
}
const navExitButtonStyle = {
    position: "absolute",
    right: 0,
    cursor: "pointer",
    backgroundImage: "url('/Images/Gallery/exitButton.png')",
    backgroundSize: "contain",
    backgroundPosition: "center",
    minHeight: "20px",
    minWidth: "20px",
};
const buttonStyle = {
    marginLeft: "auto",
}
const navButtonStyle = {
    minWidth: "70px",
    cursor: "pointer"
}
const headTitleAndImage = {
    display: "flex",
    width: "auto",
    height: "auto",
    margin: "auto auto",
    marginTop: "50px",
    justifyContent: "center",
    alignItems: "center",
}
const detailsContainer = {
    width: "500px",
}
const formSubsection = {
    width: "500px",
    height: "auto",
    textAlign: "left",
    backgroundColor: "white",
    boxShadow: "none",
    marginTop: "30px",
};
const formInputStyle = {
    width: "100%",
    marginBottom: "15px"
};
const formTextAreaStyle = {
    width: "100%",
    height: "200px",
    marginBottom: "25px"
}
const headImage = {
    height: "650px",
    minWidth: "400px",
    backgroundImage: 'url("/Symbols/pressureMan.png")',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
};
const footerSection = {
    width: "100%",
}

export default function GetQuoteExport() {
  
    let navigate = useNavigate();

    const [screenWidthState, setScreenWidthState] = useState(null);
    const [hiddenNavOn, setHiddenNavOn] = useState(false);
    const [mainElementsStyle, setMainElementsStyle] = useState({});

    const hiddenNavigationRef = useRef(null);
    const xNavCloseButtonRef = useRef(null);
    const mainElementsRef = useRef(null);
    const burgerButtonRef = useRef(null);

    useEffect(() => {
        const handleWidthChange = () => {
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            setScreenWidthState(windowWidth)
        };
        setScreenWidthState(window.innerWidth)
        window.addEventListener("resize", handleWidthChange);
        xNavCloseButtonRef.current.addEventListener("mouseenter", () => {
            xNavCloseButtonRef.current.style.animation = "rotateForwards 0.2s ease-in forwards"
        });
        xNavCloseButtonRef.current.addEventListener("mouseleave", () => {
            xNavCloseButtonRef.current.style.animation = "rotateBackwards 0.2s ease-in forwards";
        });
        return () => {
            window.removeEventListener("resize", handleWidthChange);
        }
    }, []);


    useEffect(() => {
        if(hiddenNavOn === true){
            hiddenNavigationRef.current.style.display = "initial";
            hiddenNavigationRef.current.style.animation = "rightIn 0.2s ease-out forwards";
            burgerButtonRef.current.style.animation = "";
            document.body.style.overflow = "hidden";
            const overlayStyle = {
                position: "fixed",
                height: "100vh",
                width: "100vw",
                top: 0,
                left: 0,
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                pointerEvents: "none",
            }
            setMainElementsStyle(overlayStyle);
        };
        if(hiddenNavOn === false){
            hiddenNavigationRef.current.style.animation = "rightOut 0.2s ease-in";
            burgerButtonRef.current.style.animation = "burgerLayerOut 0.3s ease-in forwards";
            document.body.style.overflow = "auto";
            hiddenNavigationRef.current.style.display = "none"
            setMainElementsStyle({});
        };
    }, [hiddenNavOn])


    const iconClean = (icon) => {
        switch(true){
            case icon === "mail":
                const subject = "Thabo was here";
                const body = "I want to ask you a question";
                const recipient = "mjpressurecleaning@gmail.com"
                const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                window.location.href = mailtoLink;
                break;
            case icon === "phone":
                window.location.href = "tel:0492964320"
                break;
            case icon === "location":
                window.location.href = "https://www.google.com/maps?q=34-36+Clithero+Avenue+Buderim"
                break;
            case icon === "facebook":
                window.location.href = "https://www.facebook.com"
                break;
            case icon === "refresh":
                navigate("/")
                break;
            case icon === "getQuote":
                navigate("/getQuote")
                break;
            case icon === "gallery":
                navigate("/gallery")
                break;
            default:
                console.log("done")
        }
    }
    
  return (
    <>
        <style>
            {
                `
                @keyframes rotateForwards{
                    0%{
                        transform: rotate(0deg)
                    }
                    100%{
                        transform: rotate(180deg)
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rotateBackwards{
                    0%{
                        transform: rotate(180deg)
                    }
                    100%{
                        transform: rotate(0deg)
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rightIn{
                    0%{
                        margin-right: -200px
                    }
                    100%{
                        margin-right: 0px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes rightOut{
                    0%{
                        margin-right: 0px
                    }
                    100%{
                        margin-right: -200px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes burgerLayerOut{
                    0%{
                        width: 0px
                    }
                    100%{
                        width: 20px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes navPanelsSlideIn{
                    0%{
                        margin-top: 30px
                    }
                    100%{
                        margin-top: 0px
                    }
                }
                `
            }
        </style>
        <style>
            {
                `
                @keyframes fadeIn{
                    0%{
                        opacity: 0.3
                    }
                    100%{
                        opacity: 1
                    }
                }
                `
            }
        </style>
        <head>
            <title>Gallery</title>
        </head>
        <div style={{...headerDetailsSection, gap: screenWidthState < 500?"30px":"4%"}}>
            <div style={subSections}>
                <div onClick={() => iconClean("mail")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/mail.png")'}}>

                </div>
                <p onClick={() => iconClean("mail")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"60px":"100px"}}>{screenWidthState < 500?"mjpres...":screenWidthState < 900?"mjpressure...":"mjpressurecleaning@gmail.com"}</p>
            </div>
            <div style={subSections}>
                <div onClick={() => iconClean("phone")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/phone.png")'}}>

                </div>
                <p onClick={() => iconClean("phone")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"60px":"100px"}}>{screenWidthState < 500?"0492...":screenWidthState < 900?"0492 96...":"0492 964 320"}</p>
            </div>
            <div style={subSections}>
                <div onClick={() => iconClean("location")} className={"buttonHover"} style={{...headerIcon, backgroundImage: 'url("/Symbols/location.png")'}}>

                </div>
                <p onClick={() => iconClean("location")} className={"buttonHover"} style={{...labelStyle, minWidth: screenWidthState < 500?"15px":screenWidthState < 900?"80px":"200px"}}>{screenWidthState < 500?"34...":screenWidthState < 900?"34 Clithero...":"34-36 Clithero Avenue Buderim"}</p>
            </div>
            <div style={facebookLogoContainer}>
                <div onClick={() => iconClean("facebook")} className={"buttonHover"} style={{...headerIcon, ...facebookLogo, marginRight: screenWidthState < 900?"":"20px"}}>

                </div>
            </div>
        </div>
        <div style={{...headerLine, marginTop: screenWidthState < 500?"15px":"20px"}}>

        </div>
        <div style={navigationSection}>
            <div onClick={() => iconClean("refresh")} style={{...logoStyle, animation: "fadeIn 0.4s ease-in forwards"}}>

            </div>
            <p onClick={() => iconClean("refresh")} className={"buttonHover"} style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Home</p>
            <p onClick={() => iconClean("gallery")} className={"buttonHover"}  style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Gallery</p>
            <p onClick={() => iconClean("getQuote")} className={"buttonHover"}  style={{...navButtonStyle, display: screenWidthState < 900?"none":"initial"}}>Get Quote</p>
            <Link to="/contact" style={{...buttonStyle, display: screenWidthState < 900?"none":"initial"}}><button>Contact</button></Link>
            <div ref={burgerButtonRef} onClick={() => setHiddenNavOn(true)} style={{...menuBurgerContainer, display: screenWidthState < 900?"flex":"none"}}>
                <div style={{...burgerLayer, position: "absolute", top: 0}}></div>
                <div style={burgerLayer}></div>
                <div style={{...burgerLayer, position: "absolute", bottom: 0}}></div>
            </div>
            <div ref={hiddenNavigationRef} style={hiddenNavigation}>
                <div style={{display: "flex", alignItems: "center", position: "relative", height: "30px"}}>
                    <h3>
                        Menu
                    </h3>
                    <div id={"carouselElement"} ref={xNavCloseButtonRef} onClick={() => setHiddenNavOn(prev => !prev)} style={navExitButtonStyle}>

                    </div>
                </div>
                <div style={headerLine}>

                </div>
                <div style={{width: "100%", height: "10px"}}>

                </div>
                <p onClick={() => iconClean("refresh")} className={"buttonHover"} style={{...hiddenNavButtonStyle}}>Home</p>
                <p onClick={() => iconClean("gallery")} className={"buttonHover"}  style={{...hiddenNavButtonStyle, animation: "navPanelsSlideIn 0.4s ease-in forwards"}}>Gallery</p>
                <p onClick={() => iconClean("getQuote")} className={"buttonHover"}  style={{...hiddenNavButtonStyle, animation: "navPanelsSlideIn 0.4s ease-in forwards"}}>Get Quote</p>
            </div>
        </div>
        <div style={{...headTitleAndImage, animation: "fadeIn 0.5s ease-in forwards", display: screenWidthState < 960?"block":"flex", marginTop: screenWidthState < 960?"20px":"50px"}}>
            <div style={{...detailsContainer, 
                    width: screenWidthState < 550?"100%":"500px",
                    marginRight: screenWidthState < 960?"auto":"",
                    marginLeft: screenWidthState < 960?"auto":"",
            }}>
                <h1 style={{fontSize: screenWidthState < 500?"22px":screenWidthState < 900?"30px":""}}>Get A Quote Today</h1>
                <h2 style={{fontSize: screenWidthState < 500?"15px":screenWidthState < 900?"16px":""}}>Leave us your details down below and we'll get back to you in 24h.</h2>
                <form target={"_blank"} action={"https://formspree.io/f/mbjnerek"} method={"POST"} style={{...formSubsection, width: screenWidthState < 700?"100%":"500px"}}>
                    <input style={formInputStyle} type="text" name="First Name" placeholder="First Name" required/>
                    <input style={formInputStyle} type="text" name="Last Name" placeholder="Last Name" required/>
                    <input style={formInputStyle} type="text" name="Email" placeholder="Email" required/>
                    <input style={formInputStyle} type="text" name="Phone Number" placeholder="Phone Number" required/>
                    <input style={formInputStyle} type="text" name="Suburb" placeholder="Suburb" required/>
                    <textarea style={formTextAreaStyle} type="text" name="Inquiry" placeholder="Inquiry Details" required/>
                    <button type="submit">Submit</button>
                </form>
            </div>
            <div style={{...headImage, minWidth: screenWidthState < 550?"":"400px", opacity: screenWidthState < 900?"0.1":""}}>

            </div>
        </div>
        <div style={footerSection}>
            <div style={{...headerLine, height: "0.8px", marginTop: screenWidthState < 500?"50px":"100px"}}>

            </div>
            <div style={navigationSection}>
                <div onClick={() => iconClean("refresh")} style={{...logoStyle, minWidth: screenWidthState < 500?"80px":screenWidthState < 580?"120px":"140px", height: screenWidthState < 500?"80px":screenWidthState < 580?"90px":"80px"}}>

                </div>
                <p style={{...navButtonStyle, curosr: "initial"}}>by Montarch Web Design</p>
                <div style={facebookLogoContainer}>
                    <div onClick={() => iconClean("facebook")} class={"buttonHover"} style={{...headerIcon, ...facebookLogo}}>

                    </div>
                </div>
            </div>
        </div>
        <div ref={mainElementsRef} style={mainElementsStyle}>

        </div>
    </>
  )
}
