import HomeExport from "./pages/home/homeExport";
import ContactExport from "./pages/contact/contactExport";
import GetQuoteExport from "./pages/getQuote/getQuoteExport";
import GalleryExport from "./pages/gallery/galleryExport";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useState, useEffect, useRef} from "react";

const marginAndPageExport = {
  display: "flex",
  width: "100vw",
  justifyContent: "center"
}
const mainSection = {
  maxWidth: "1350px",
  position: "relative",
  height: "auto",
  paddingLeft: "25px",
  paddingRight: "25px",
  width: "1350px"
}

function App() {

  const [screenWidthState, setScreenWidthState] = useState(1341)

  useEffect(() => {
      const handleWidthChange = () => {
          const windowWidth = window.innerWidth;
          setScreenWidthState(windowWidth)
      }
      window.addEventListener("resize", handleWidthChange);
      return () => {
          window.removeEventListener("resize", handleWidthChange);
      }
  }, []);


  return (
    <div style={marginAndPageExport}>
      <div style={mainSection}>
      <BrowserRouter>
        <Routes>
            <Route path={"/"} element={<HomeExport />} />
            <Route path="/contact" element={<ContactExport />}/>
            <Route path={"/getQuote"} element={<GetQuoteExport />}/>
            <Route path={"/gallery"} element={<GalleryExport />}/>
        </Routes>
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
